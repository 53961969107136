<template>
    <form v-if="roles" class="my-form" @submit.prevent="onSubmit">
        <Dialog :visible.sync="leaveDialog" header="" position="center" :modal="true" :closeOnEscape="true" :closable="false">
            <div class="p-dialog-content p-ai-center p-d-flex">
                <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem"></i>
                <span>Вы вносили данные. Вы уверены, что хотите покинуть страницу без сохранения данных?</span>
            </div>

            <template #footer>
                <Button class="p-button p-button-text p-button-danger" @click="clearAndLeave">
                    <span class="pi pi-times p-button-icon p-button-icon-left"></span>
                    <span class="p-button-label">Удалить данные и покинуть</span>
                </Button>
                <Button class="p-button p-component p-button-text" type="button" autofocus @click="leaveDialog = false">
                    <span class="pi pi-check p-button-icon-left"></span>
                    <span class="p-button-label">Вернуться к заполнению</span>
                    <span class="p-ink"></span>
                </Button>
            </template>
        </Dialog>
        <div class="p-fluid p-formgrid p-grid p-mx-0 p-my-2">
            <div class="p-field p-col-12 p-md-6">
                <label for="login">Логин*</label>
                <InputText id="login" name="login" v-model.trim="v$.formData.login.$model" placeholder="Ваш логин" :class="v$.formData.login.$invalid && submitted ? 'p-error' : ''" disabled />
                <small v-show="submitted" v-for="error in showErrors(v$.formData.login)" :key="error.$uid" class="p-error">
                    <small>{{ error.$message }}</small
                    ><br />
                </small>
            </div>
            <div class="p-field p-col-12 p-md-6">
                <label>Новый пароль</label>
                <InputText
                    type="password"
                    name="newPassword"
                    v-model="formData.newPassword"
                    placeholder="Новый пароль"
                    @input="notSameAs"
                    :class="(v$.formData.newPassword.$invalid || passwordError.length > 0) && submitted ? 'p-error' : ''"
                />
                <small v-show="submitted" v-for="error in showErrors(v$.formData.newPassword)" :key="error.$uid" class="p-error">
                    <small>{{ error.$message }}</small><br>
                </small>
                <small v-show="submitted && passwordError.length > 0" class="p-error">
                    <small>{{ passwordError }}</small><br>
                </small>
            </div>
            <div class="p-field p-col-12 p-md-6">
                <label for="email">E-mail*</label>
                <InputText id="email" name="email" v-model.trim="v$.formData.email.$model" placeholder="Ваш емаил" :class="v$.formData.email.$invalid && submitted ? 'p-error' : ''" disabled />
                <small v-show="submitted" v-for="error in showErrors(v$.formData.email)" :key="error.$uid" class="p-error">
                    <small>{{ error.$message }}</small
                    ><br />
                </small>
            </div>
            <div class="p-field p-col-12 p-md-6">
                <label>Подтвердить пароль</label>
                <InputText
                    type="password"
                    name="passwordConfirm"
                    v-model="formData.passwordConfirm"
                    placeholder="Подтвердить пароль"
                    @input="sameAs"
                    :class="passwordError.length > 0 && submitted ? 'p-error' : ''"
                />
                <small v-show="submitted && passwordError.length > 0" class="p-error">
                    <small>{{ passwordError }}</small><br>
                </small>
            </div>
            <div class="p-field p-col-12 p-md-6">
                <label for="lastName">Фамилия*</label>
                <InputText id="lastName" name="lastName" v-model.trim="v$.formData.lastName.$model" placeholder="Фамилия" :class="v$.formData.lastName.$invalid && submitted ? 'p-error' : ''" />
                <small v-show="submitted" v-for="error in showErrors(v$.formData.lastName)" :key="error.$uid" class="p-error">
                    <small>{{ error.$message }}</small><br>
                </small>
            </div>
            <div class="p-field p-col-12 p-md-6">
                <label for="workPosition">Должность</label>
                <InputText id="workPosition" name="workPosition" v-model.trim="v$.formData.workPosition.$model" placeholder="Должность" :class="v$.formData.workPosition.$invalid && submitted ? 'p-error' : ''" />
                <small v-show="submitted" v-for="error in showErrors(v$.formData.workPosition)" :key="error.$uid" class="p-error">
                    <small>{{ error.$message }}</small><br>
                </small>
            </div>
            <div class="p-field p-col-12 p-md-6">
                <label for="firstName">Имя*</label>
                <InputText id="firstName" name="firstName" v-model.trim="v$.formData.firstName.$model" placeholder="Имя" :class="v$.formData.firstName.$invalid && submitted ? 'p-error' : ''" />
                <small v-show="submitted" v-for="error in showErrors(v$.formData.firstName)" :key="error.$uid" class="p-error">
                    <small>{{ error.$message }}</small><br>
                </small>
            </div>
            <div class="p-field p-col-12 p-md-6">
                <label for="phone">Телефон</label>
                <InputText id="phone" name="phone" v-model.trim="v$.formData.phone.$model" placeholder="Ваш контактный телефон" :class="v$.formData.phone.$invalid && submitted ? 'p-error' : ''" />
                <small v-show="submitted" v-for="error in showErrors(v$.formData.phone)" :key="error.$uid" class="p-error">
                    <small>{{ error.$message }}</small><br>
                </small>
            </div>
            <div class="p-field p-col-12 p-md-6">
                <label for="patronymic">Отчество</label>
                <InputText id="patronymic" name="patronymic" v-model="formData.patronymic" placeholder="Отчество" />
            </div>
            <div class="p-field p-col-12 p-md-6">
                <label>Роль</label>
                <Dropdown
                    v-model="formData.role.id"
                    :options="filteredRoles"
                    option-label="name"
                    option-value="id"
                    placeholder="Выберите роль"
                    data-key="id"
                    :disabled="true"
                />
            </div>
            <div class="p-field p-col-12 p-md-6">
                <label>Организация</label>
                <Dropdown
                    v-model="formData.organization.id"
                    option-value="id"
                    placeholder="Выберите из списка организаций"
                    :options="organizations"
                    option-label="publicName"
                    data-key="id"
                    :disabled="true"
                />
            </div>
        </div>
        <div class="p-grid p-mx-0">
            <div class="p-col-12 p-md-6 p-d-flex p-ai-center p-jc-between">
                <div class="p-field-checkbox">
                    <Checkbox :binary="true" id="active" v-model="v$.formData.active.$model" :disabled="true" />
                    <label for="active">Активный</label>
                </div>
            </div>
        </div>
        <div class="p-col-12 p-grid p-mx-0">
            <Button type="submit">Сохранить</Button>
        </div>
    </form>
</template>

<script>
import { required, minLength, email, helpers, maxLength } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { jsonApiListParser } from '@/main/utils/common';
import { mapGetters } from 'vuex';
import { getUserById, patchUser } from '@/api/user';
import { requestToastHandler } from '@/main/mixins';
import { pushOrUpdate } from '@/utils/common';

export default {
    name: 'userProfile',
    emits: ['loadingChange'],
    mixins: [requestToastHandler],
    setup: () => ({ v$: useVuelidate() }),
    data: () => ({
        leaveRoute: '/',
        leaveDialog: false,
        isSubmitButtonDirty: false,
        userInfo: null,
        userIncluded: [],
        formData: {
            login: '',
            newPassword: '',
            passwordConfirm: '',
            firstName: '',
            lastName: '',
            patronymic: '',
            email: '',
            phone: '',
            workPosition: '',
            organization: { value: null, id: '', type: '' },
            role: { value: null, id: '', type: '' },
            active: false,
        },
        inputGroupClasses: ['p-field', 'p-fluid', 'p-d-flex', 'p-flex-column', 'p-ai-start', 'p-col-12', 'p-pblank-0'],
        organizations: [],
        roles: [],
        filteredOrganizations: [],
        filteredRoles: [],
        submitted: false,
        userId: null,
        passwordError: '',
        passwordTimeout: null
    }),
    validations() {
        const standart = (minLen = 5) => ({
            required: { ...required, $message: 'Поле обязательно к заполнению' },
            minLength: { ...minLength(minLen), $message: `Значение не должно быть короче ${minLen} символа(ов)` },
        });
        const telephoneMask = (val) => val ? (/^[0-9-+)( ]+$/.test(val) && val.length > 1 && val.length < 256) : true;
        return {
            formData: {
                workPosition: {
                    minLength: { ...minLength(3), $message: 'Значение не должно быть короче 3 символов' },
                },
                login: standart(3),
                phone: { telephoneMask: helpers.withMessage('Неверный формат телефона', telephoneMask) },
                newPassword: {
                    minLength: { ...minLength(6), $message: 'Значение не должно быть короче 6 символов' },
                    maxLength: { ...maxLength(72), $message: 'Значение не должно быть больше 72 символов' },
                },
                firstName: {
                    required: { ...required, $message: 'Поле обязательно к заполнению' },
                    minLength: { ...minLength(2), $message: `Значение не должно быть короче ${2} символов` }
                },
                lastName: {
                    required: { ...required, $message: 'Поле обязательно к заполнению' },
                    minLength: { ...minLength(2), $message: `Значение не должно быть короче ${2} символов` }
                },
                email: {
                    required: { ...required, $message: 'Поле обязательно к заполнению' },
                    email: { ...email, $message: 'Введите корректный e-mail' },
                },
                active: {},
            },
        };
    },
    methods: {
        clearAndLeave() {
            this.v$.$anyDirty = false;
            this.$router.push(this.leaveRoute);
        },
        async onSubmit() {
            this.submitted = true;
            if (!this.v$.$invalid && this.passwordError.length === 0) {
                const dataToServer = {
                    data: {
                        type: 'user',
                        id: this.userId || undefined,
                        attributes: {
                            email: this.formData.email,
                            login: this.formData.login,
                            password: this.formData.newPassword || undefined,
                            firstName: this.formData.firstName,
                            lastName: this.formData.lastName,
                            patronymic: this.formData.patronymic,
                            phone: this.formData.phone,
                            workPosition: this.formData.workPosition,
                            active: this.formData.active,
                        },
                        relationships: {
                            organization: {
                                data: {
                                    type: this.formData.organization.type,
                                    id: this.formData.organization.id,
                                },
                            },
                            role: {
                                data: {
                                    type: this.formData.role.type,
                                    id: this.formData.role.id,
                                },
                            },
                        },
                    },
                };

                try {
                    this.$emit('loadingChange', true);
                    const result = await patchUser(dataToServer, this.userId);

                    if (result.message) {
                        this.$requestError(result.message);
                        return;
                    }

                    this.v$.$anyDirty = false;
                    this.$toast.add({
                         severity: 'success',
                         summary: 'Пользователь успешно изменён.',
                         life: 2000,
                    });
                } catch (error) {
                    this.$requestError(error.message);
                } finally {
                    this.$emit('loadingChange');
                }
            }
        },
        notSameAs() {
            clearTimeout(this.passwordTimeout);

            this.passwordTimeout = setTimeout(async () => {
                this.passwordError = '';
                if (this.formData.newPassword?.length > 0 && this.formData.newPassword === this.formData.login) {
                    this.passwordError = 'Логин и пароль не должны совпадать';
                }
            }, 500);
        },
        sameAs() {
            clearTimeout(this.passwordTimeout);

            this.passwordTimeout = setTimeout(async () => {
                this.passwordError = '';
                if (this.formData.newPassword?.length > 0 && this.formData.newPassword !== this.formData.passwordConfirm) {
                    this.passwordError = 'Пароли должны совпадать';
                }
            }, 500);
        },
        async getCurrentUserById() {
            try {
                const { data: { id, attributes: userData, relationships: { role, organization } }, included } = await getUserById(this.currentUser.id);
                this.userInfo = userData;
                this.userId = id;
                this.userIncluded = included;
                this.formData = userData;
                this.formData.role = role.data;
                const filteredRoles = this.userIncluded.filter(i => i.type === 'role' && i.id === role.data.id)
                const [roleObj] = jsonApiListParser(filteredRoles);
                pushOrUpdate(this.filteredRoles, roleObj)
                if (organization.data) {
                    this.formData.organization = organization.data;
                    const orgObj = included.find(i => i.id === organization.data.id)
                    const [org] = jsonApiListParser([orgObj], included)
                    pushOrUpdate(this.organizations, org)
                }
            } catch (error) {
                this.$requestError(error.message);
            }
        },
        showErrors(data) {
            return data.$errors.length ? data.$errors : data.$silentErrors;
        },
    },
    computed: {
        ...mapGetters('auth', ['currentUser'])
    },
    beforeRouteLeave(to, from, next) {
        if (this.v$.$anyDirty) {
            this.$root.$emit('show-leave-dialog', {
                acceptCb: async () => await this.onSubmit(),
                beforeClose: next
            })
            return;
        }
        next();
    },

    async mounted() {
        await this.getCurrentUserById();
        this.$emit('loadingChange');
    },
};
</script>

<style scoped lang="scss">
.my-form {
    background-color: #f8f9fa;
    border-radius: 4px;
    padding: 1rem;
}
label {
    display: block;
    padding-left: 0.1rem;
    padding-bottom: 4px;
    margin-bottom: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: #272727;
}
.p-field-checkbox {
    label {
        padding-bottom: 0.1rem;
    }
}
::v-deep .p-error {
    input {
        border-color: #f44336 !important;
    }
}
.p-inputtext {
    padding: 8px 12px !important;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
}
::v-deep .p-autocomplete {
    .p-autocomplete-input {
        border-right: none;
    }
    .p-autocomplete-dropdown {
        background: transparent;
        color: #8794a3;
        width: 2.357rem;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        background: #ffffff;
        border: none;
        border-top: 1px solid #eaeaea;
        border-right: 1px solid #eaeaea;
        border-bottom: 1px solid #eaeaea;
    }
}
</style>
